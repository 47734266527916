import { Route } from '@angular/router';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { AuthGuard } from '@core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { Page404Component } from './authentication/page404/page404.component';
import { Role } from '@core';

export const APP_ROUTE: Route[] = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      // { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      { path: '', redirectTo: '/authentication/landingPage', pathMatch: 'full' },

      {
        path: 'admin',
        canActivate: [AuthGuard],
        data: {
          role: Role.State + ' ' + Role.Superadmin,
        },
        loadChildren: () =>
          import('./admin/admin.routes').then((m) => m.ADMIN_ROUTE),
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        data: {
          role: Role.Superadmin,
        },
        loadChildren: () =>
          import('./admin/admin.routes').then((m) => m.ADMIN_ROUTE),
      },
      {
        path: 'school',
        canActivate: [AuthGuard],
        data: {
          role: Role.school,
        },
        loadChildren: () =>
          import('./school/school.routing').then((m) => m.school_Route),
      },
      {
        path: 'student',
        canActivate: [AuthGuard],
        data: {
          role: Role.Student,
        },
        loadChildren: () =>
          import('./student/student.routes').then((m) => m.STUDENT_ROUTE),
      },                
      {
        path: 'ui',
        loadChildren: () => import('./ui/ui.routes').then((m) => m.UI_ROUTE),
      },
      {
        path: 'users',
        canActivate: [AuthGuard],
        data: {
          role: Role.Superadmin+' '+Role.cluster,
        },
        loadChildren: () =>
          import('./user-logins/users.routes').then((m) => m.USERS_ROUTE),
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./icons/icons.routes').then((m) => m.ICONS_ROUTE),
      }, 
      {
        path: 'counsellor',
        canActivate: [AuthGuard],
        data: {
          role: Role.counsellor,
        },
         loadChildren: () => import('./sansthan/sansthan.route').then((m) => m.Sansthan_Route)
      },
      {
        path: 'skill-trainer',
        canActivate: [AuthGuard],
        data: {
          role: Role.skillCounsellor,
        },
         loadChildren: () => import('./skill-counsellor/skill-counsellor.routing').then((m) => m.Skill_Counsellor)
      },
      {
        path: 'cluster',
        canActivate: [AuthGuard],
        data: {
          role: Role.cluster,
        },
        loadChildren: () => import('./cluster/cluster.routing').then((m) => m.Cluster_Route)
      },
      {
        path: 'department',
        canActivate: [AuthGuard],
        data: {
          role: Role.department,
        },
        loadChildren: () => import('./department/department.routing').then((m) => m.department_routing)
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./user-logins/users.routes').then((m) => m.USERS_ROUTE),
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/auth.routes').then((m) => m.AUTH_ROUTE),
  },
  { path: '**', component: Page404Component },
];
