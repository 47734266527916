import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { throwError, Observable, of } from 'rxjs';
import { HttpRequest, HttpHandlerFn, HttpEvent } from '@angular/common/http';

export const jwtInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);
  
  // Check if the request is for refreshing tokens, skip adding Authorization header for this request
  if (req.url.includes('refresh-tokens')) {
    return next(req);
  }

  const currentUser = authService.currentUserValue;

  // Clone the request and add Authorization header if the user is logged in
  if (currentUser && authService.token) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authService.token}`,
      },
    });
  }

  // Continue with the request and catch any errors
  return next(req).pipe(
    catchError(err => {
      // If we get a 401 response, attempt to refresh the token
      if (err.status === 401 && !req.url.includes('refresh-tokens')) {
        const refreshToken = JSON.parse(sessionStorage.getItem('tokens') || '{}').refresh?.token;

        if (!refreshToken) {
          authService.logout();
          return throwError(() => new Error('No refresh token available'));
        }

        // Use the refresh token to get a new access token
        return authService.post('refresh-tokens', { refreshToken }).pipe(
          switchMap((response) => {
            // Store the new tokens
            authService.setRefreshToken(response);
            
            // Retry the failed request with the new token
            const updatedReq = req.clone({
              setHeaders: {
                Authorization: `Bearer ${response.tokens.access.token}`,
              },
            });
            return next(updatedReq);
          }),
          catchError(refreshErr => {
            // If refreshing the token fails, log out the user and propagate the error
            authService.logout();
            return throwError(() => refreshErr);
          })
        );
      }

      // If another error occurs, propagate it
      return throwError(() => err);
    })
  );
};
