import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { DirectionService } from './direction.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  apiURL: any = 'https://pathadarshi.dmfkeonjhar.in/v1/'; // Production server
  // apiURL: any = 'http://35.200.136.5:3000/v1/'; // Production server
  // apiURL: any = 'http://139.59.21.236:3001/v1/'; // local server
  // apiURL: any = 'http://192.168.0.129:5000/v1/'; // wifi server

  token = '';
  headerToken: any;
  loginDetails: any;

  constructor(private http: HttpClient,private route:Router,private directionService:DirectionService) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.setTokens();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public set currentUserValue(user: any) {
    this.currentUserSubject.next(user);
  }

  post(url: string, data: any) {
    if (url.includes('refresh-tokens')) {
      return this.http.post<any>(this.apiURL + url, data);
    } else {
      return this.http.post<any>(this.apiURL + url, data);
    }
  }

  patch(url: string, data: any) {
    const type = Object.getPrototypeOf(data);
    let id: any;
    if (type.append) {
      const stringifyId = JSON.stringify(Object.fromEntries(data));
      const pasrseId = JSON.parse(stringifyId);
      id = pasrseId.id;
      data.delete("id");
    } else {
      id = data.id;
      delete data.id;
    }
    return this.http.patch<any>(this.apiURL + url + "/" + id, data);
  }

  get(url: string) {
    return this.http.get<any>(this.apiURL + url);
  }

  getById(url: string, id: string) {
    return this.http.get<any>(this.apiURL + url + "/" + id);
  }

  delete(url: string, id: any) {
    return this.http.delete<any>(this.apiURL + url + "/" + id);
  }

  setTokens() {
    const token = JSON.parse(sessionStorage.getItem('tokens') || '{}');
    if (token && token.access) {
      this.token = token.access.token;
      this.headerToken = new HttpHeaders({
        'Authorization': `Bearer ${this.token}`
      });
    }
  }

  setRefreshToken(data: any) {
    sessionStorage.setItem('tokens', JSON.stringify(data));
    this.setTokens();
  }

  setLoginDetails(details: any) {
    this.loginDetails = details.user;
    sessionStorage.setItem('userProfile', JSON.stringify(details.user));
    sessionStorage.setItem('tokens', JSON.stringify(details.tokens));
    this.setTokens();
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(this.currentUserValue);
    this.directionService.hideDialog();
    // this.http.get('https://margdarshak.eduspark.co.in/logout').subscribe({
    //   next: (response) => {
    //     console.log('Logged out successfully', response);
    //   },
    //   error: (error) => {
    //     console.error('Error during logout', error);
    //   }
    // });
    if(this.currentUserValue.role == 'student'){
      window.open(`https://margdarshak.eduspark.co.in/logout`, "_blank");
    }
    return of({ success: false });   
    
  }
}
