import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  return next(req).pipe(
    catchError((err) => {
      if (err.status === 401) {
        // auto logout if 401 response returned from API
        authService.logout();
        location.reload();
      }

      const error = err.error.message || err.statusText;
      console.log('error_Interceptor',error);
      return throwError(() => new Error(error));  // Wrapping error in `throwError`
    })
  );
};

// import { AuthService } from '../service/auth.service';
// import { Injectable } from '@angular/core';
// import {
//   HttpRequest,
//   HttpHandler,
//   HttpEvent,
//   HttpInterceptor,
// } from '@angular/common/http';
// import { Observable, throwError } from 'rxjs';
// import { catchError } from 'rxjs/operators';

// @Injectable()
// export class ErrorInterceptor implements HttpInterceptor {
//   constructor(private authenticationService: AuthService) { }

//   intercept(
//     request: HttpRequest<any>,
//     next: HttpHandler
//   ): Observable<HttpEvent<any>> {
//     return next.handle(request).pipe(
//       catchError((err) => {
//         if (err.status === 401) {
//           // auto logout if 401 response returned from api
//           this.authenticationService.logout();
//           location.reload();
//         }

//         const error = err.error.message || err.statusText;
//         return throwError(error);
//       })
//     );
//   }
// }
